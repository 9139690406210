import { DetailedPlot, Plot } from "@/types";

const price = (plot: Plot | DetailedPlot) => {
  if (!plot.price) return "Onbeschikbaar";

  const format = currency(parseInt(plot.price));

  return `${format} ${period(plot.project_type)}`;
};

const currency = (amount: number) => {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(amount);
};

const period = (type: Plot["type"], show: boolean = true) => {
  switch (type) {
    case "rent":
      return show ? "p.mnd." : "";
    case "sale":
    default:
      return show ? "v.o.n." : "";
  }
};

export { currency, period, price };
